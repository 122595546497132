export enum CriteriaType {
    KillNPC = 0,
    RaiseSkillLine = 7,
    EarnAchievement = 8,
    CompleteQuest = 27,
    HaveSpellCastOnYou = 28,
    CastSpell = 29,
    LearnOrKnowSpell = 34,
    AcquireItem = 36,
    UseItem = 41,
    LootItem = 42,
    ReputationGained = 46,
    EquipItem = 57,
    GainAura = 69,
    AccountKnowsPet = 96,
    ObtainPetThroughBattle = 157,
    GarrisonMissionSucceeded = 174,
    LearnToy = 185,
    LearnHeirloom = 188,
    LearnTransmog = 192,
    GarrisonTalentCompleteResearchAny = 197,
    CollectTransmogSet = 205,
    HonorMaybe = 213,
}
