export enum ItemBonusType
{
    NoBonus = 0,
    IncreaseItemLevel = 1,
    IncreaseBonusStat = 2,
    SetItemQuality = 3,
    AddItemNameDescription = 4,
    SetNameSuffix = 5,
    AddSockets = 6,
    SetItemAppearanceModifier = 7,
    IncreaseRequiredLevel = 8,
    DisplayToast = 9,
    MultiplyRepairCost = 10,
    ScalingStatDistribution = 11,
    SetDisenchantLoot = 12,
    FixedStatDistribution = 13,
    PreviewAsItemPyramid = 14,
    IsClientPreview = 15,
    SetBindType = 16,
    SecondaryRelicPowerLabel = 17,
    SetRequiredLevel = 18,
    AzeriteTierUnlockSetId = 19,
    Unknown20 = 20,
    CanDisenchant = 21,
    CanScrap = 22,
    ItemEffectId = 23,
    Unknown24 = 24,
    ModifiedCraftingStat = 25,
    Unknown26 = 26,
    RequiredLevelCurve = 27,
    SetIconFiledataId = 28,
    Unknown29 = 29,
    ItemDescription = 30,
    LegendaryName = 31,
    Unknown32 = 32,
    Unknown33 = 33,
    ItemBonusListGroupId = 34,
    ItemLimitCategoryId = 35,
    Unknown36 = 36,
    ItemConversionId = 37,
    Unknown38 = 38,
    Unknown39 = 39,
    Unknown40 = 40,
    Unknown41 = 41,
    BaseItemLevel = 42,
    Unknown43 = 43,
    Unknown44 = 44,
}
