export enum LookupType {
    None,
    Achievement,
    Currency,
    Illusion,
    Mount,
    Pet,
    Quest,
    Recipe,
    Reputation,
    Toy,
    Transmog,
    TransmogSet,
}
