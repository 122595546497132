export enum Language {
    enUS = 0,
    //koKR = 1,
    frFR = 2,
    deDE = 3,
    //zhCN = 4,
    esES = 5,
    //zhTW = 6,
    esMX = 7,
    ruRU = 8,
    ptBR = 10,
    itIT = 11,
}
